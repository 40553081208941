import * as React from "react";
import {
  TextContainer,
  SkeletonBodyText,
  SkeletonDisplayText,
  Card
} from "@shopify/polaris";
const Loading = () => (
  <Card sectioned>
    <TextContainer>
      <SkeletonDisplayText size="small" />
      <SkeletonBodyText />
    </TextContainer>
  </Card>
);

export default Loading;
