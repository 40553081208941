import { RootStore } from ".";
import { Document, Collection } from "../firestorter";
import { computed, observable } from "mobx";
import {
  DocumentSource,
  IDocumentOptions,
  Mode,
  ICollectionOptions,
  CollectionSource
} from "../firestorter/Types";
import { struct } from "superstruct";
import { firestore } from "firebase/firestore";

const InvoiceItem = struct({
  amount: "number",
  price: "number",
  description: "string"
});

export class Invoice extends Document {
  constructor(source: DocumentSource, options: IDocumentOptions) {
    super(source, {
      ...(options || {}),
      schema: struct({
        issueDate: "string",
        payByDate: "string",
        invoiceNumber: "number",
        client: "string",
        tax: "number",
        items: [InvoiceItem]
      })
    });
  }

  get total() {
    return this.data.items
      .map(i => i.amount * i.price)
      .reduce((acc, c) => acc + c);
  }
}

export class InvoiceStore {
  constructor(private rootStore: RootStore) {}
  @observable error: string;
  @observable saving: boolean;
  @observable removing: boolean;

  collection = new Collection<Invoice>(
    () => `users/${this.rootStore.userId}/invoices`,
    {
      query: (ref: firestore.Query) => ref.orderBy("invoiceNumber", "desc"),
      createDocument: (
        source: CollectionSource,
        options: ICollectionOptions<Invoice>
      ) => new Invoice(source, options)
    }
  );

  changeSorting(field: string, order: "desc" | "asc") {
    this.collection.query = (ref: firestore.Query) => ref.orderBy(field, order);
  }

  public invoiceData(id: string) {
    return computed(
      () => this.collection.docs.find(item => item.id === id) as Invoice
    );
  }

  public save = async (data: any) => {
    this.saving = true;
    this.error = undefined;
    try {
      if (this.invoiceDocument.hasData) {
        await this.invoiceDocument.update(data);
      } else {
        const id = await this.rootStore.incCounter("invoiceCounter");
        await this.collection.add({
          ...data,
          invoiceNumber: id
        });
      }
      this.rootStore.user.update({ lastClient: data.client });
      this.rootStore.routerStore.goTo("invoices");
    } catch (ex) {
      console.log(ex);
      this.error = ex.message;
    }
    this.saving = false;
  };

  public delete = async () => {
    this.error = undefined;
    this.removing = true;
    try {
      await this.invoiceDocument.delete();
      this.rootStore.routerStore.goTo("invoices");
    } catch (ex) {
      console.log(ex);
      this.error = ex.message;
    }
    this.removing = false;
  };

  invoiceDocument = new Invoice(undefined, { mode: Mode.Off });

  public getInvoiceTitle(invoice) {
    return computed(() => {
      const clientDoc = this.rootStore.clients
        .clientData(invoice.data.client)
        .get();
      return `Invoice #${invoice.data.invoiceNumber} for ${
        clientDoc ? clientDoc.data.fullName : ""
      }`;
    });
  }

  public loadInvoiceDocument = async (id: string) => {
    this.invoiceDocument.path = `users/${this.rootStore.userId}/invoices/${id}`;
    await this.invoiceDocument.fetch();
    return this.invoiceDocument;
  };

  public print = async (id: string) => {
    await this.rootStore.templates.print(
      this.rootStore.user.data.invoiceTemplate,
      this.invoiceData(id).get()
    );
  };

  public savePDF = async (id: string) => {
    const invoice = this.invoiceData(id).get();
    await this.rootStore.templates.savePDF(
      this.rootStore.user.data.invoiceTemplate,
      invoice,
      this.getInvoiceTitle(invoice).get()
    );
  };
}
