import { RootStore, numberWithCommas } from ".";
import { Document, Collection } from "../firestorter";
import { computed, observable, autorun } from "mobx";
import {
  DocumentSource,
  IDocumentOptions,
  Mode,
  CollectionSource,
  ICollectionOptions
} from "../firestorter/Types";
import { struct } from "superstruct";
import * as firebase from "firebase/app";

export const ReceiptFieldMap = {
  ISSUE_DATE: (receipt, _) => receipt.data.issueDate,
  OWNER_NAME: (_receipt, user) => user.data.fullName,
  OWNER_ADDRESS: (_receipt, user) => user.data.address,
  OWNER_PHONE: (_receipt, user) => user.data.phone,
  OWNER_ID: (_receipt, user) => user.data.businessNumber,
  CLIENT_NAME: (_receipt, _user, client) => client.data.fullName,
  CLIENT_ADDRESS: (_receipt, _user, client) => client.data.address,
  CLIENT_ID: (_receipt, _user, client) => client.data.businessNumber,
  PAY_DATE: (receipt, _user) => receipt.data.paymentDate,
  PAY_METHOD: (receipt, _user) => receipt.data.paymentMethod,
  RECEIPT_NUMBER: (receipt, _user) => receipt.data.receiptNumber,
  PRICE: (invoice, _user) => numberWithCommas(invoice.data.amount)
};

export const InvoiceFieldMap = {
  ISSUE_DATE: (invoice, _) => invoice.data.issueDate,
  OWNER_NAME: (_invoice, user) => user.data.fullName,
  OWNER_ADDRESS: (_invoice, user) => user.data.address,
  OWNER_PHONE: (_invoice, user) => user.data.phone,
  OWNER_ID: (_invoice, user) => user.data.businessNumber,
  CLIENT_NAME: (_invoice, _user, client) => client.data.fullName,
  CLIENT_ADDRESS: (_invoice, _user, client) => client.data.address,
  CLIENT_ID: (_invoice, _user, client) => client.data.businessNumber,
  INVOICE_NUMBER: (invoice, _user) => invoice.data.invoiceNumber,
  TOTAL_BEFORE_TAX: (invoice, _user) => numberWithCommas(invoice.total),
  PAY_BY: (invoice, _user) => invoice.data.payByDate,
  TAX_AMOUNT: (invoice, _user) => invoice.data.tax,
  TAX: (invoice, _user) =>
    numberWithCommas((invoice.total * invoice.data.tax) / 100),
  TOTAL_AFTER_TAX: (invoice, _user) =>
    numberWithCommas((invoice.total * invoice.data.tax) / 100 + invoice.total)
};

export const ITEM_BLOCK_BEGIN = "{%ITEM_BLOCK_BEGIN%}";
export const ITEM_BLOCK_END = "{%ITEM_BLOCK_END%}";

export const ItemFieldMap = {
  ITEM_NAME: item => item.description,
  ITEM_AMOUNT: item => item.amount,
  ITEM_PRICE: item => numberWithCommas(item.price),
  ITEM_TOTAL: item => numberWithCommas(item.price * item.amount)
};

export class Template extends Document {
  constructor(source: DocumentSource, options: IDocumentOptions) {
    super(source, {
      ...(options || {}),
      schema: struct({
        name: "string",
        type: "string",
        content: "string"
      })
    });
  }
  get isSystem() {
    return (this.path as string).indexOf("templates") === 0;
  }
}

export class TemplateStore {
  constructor(private rootStore: RootStore) {
    autorun(async () => {
      const docs = this.localTemplates.docs.concat(
        ...this.globalTemplates.docs
      );
      for (const element of docs) {
        if (!this.images.has(element.id)) {
          try {
            this.images.set(
              element.id,
              await firebase
                .storage()
                .ref(`thumb@_${element.id}.png`)
                .getDownloadURL()
            );
          } catch {
            this.images.set(element.id, undefined);
          }
        }
      }
    });
  }

  images = observable.map();
  @observable templateSaving = false;
  @observable templateDeleting = false;
  @observable error: string;

  globalTemplates = new Collection<Template>(() => `templates`, {
    query: ref => ref.orderBy("name", "desc"),
    mode: Mode.On,
    createDocument: (
      source: CollectionSource,
      options: ICollectionOptions<Template>
    ) => new Template(source, options)
  });

  localTemplates = new Collection<Template>(
    () => `users/${this.rootStore.userId}/templates`,
    {
      query: ref => ref.orderBy("name", "desc"),
      mode: Mode.On,
      createDocument: (
        source: CollectionSource,
        options: ICollectionOptions<Template>
      ) => new Template(source, options)
    }
  );

  @computed get allInvoiceTemplates() {
    return this.localTemplates.docs
      .concat(...this.globalTemplates.docs)
      .filter(d => d.data.type === "invoice");
  }

  @computed get allReceiptTemplates() {
    return this.localTemplates.docs
      .concat(...this.globalTemplates.docs)
      .filter(d => d.data.type === "receipt");
  }

  public templateData(id) {
    return computed(() => {
      let ret = this.globalTemplates.docs.find(
        item => item.id === id
      ) as Template;
      if (!ret)
        ret = this.localTemplates.docs.find(item => item.id === id) as Template;
      return ret;
    });
  }

  templateDocument = new Template(undefined, { mode: Mode.Off });

  public async loadTemplateDocument(id: string) {
    this.templateDocument.path = `users/${
      this.rootStore.userId
    }/templates/${id}`;
    await this.templateDocument.fetch();
    console.log(this.templateDocument.data);
    return this.templateDocument;
  }

  selectTemplate(id: any): void {
    const tpl = this.templateData(id).get();
    this.rootStore.saveSettings({
      [`${tpl.data.type}Template`]: id
    });
  }

  save = async (id, data) => {
    const tpl = this.templateData(id).get();
    this.templateSaving = true;
    try {
      await tpl.update(data);
    } catch (ex) {
      this.error = ex;
      this.rootStore.toastMessage = "Cannot save template!";
    }
    this.templateSaving = false;
  };

  remove = async id => {
    const tpl = this.templateData(id).get();
    if (
      id === this.rootStore.user.data.receiptTemplate ||
      id === this.rootStore.user.data.invoiceTemplate
    ) {
      this.rootStore.toastMessage = "Cannot delete template, it's selected!";
      return;
    }
    this.templateDeleting = true;
    try {
      await tpl.delete();
    } catch (ex) {
      this.error = ex;
      this.rootStore.toastMessage = "Cannot delete template!";
    }
    this.templateDeleting = false;
  };

  async cloneTemplate(id: any) {
    const tpl = this.templateData(id).get();
    const newTpl = await this.localTemplates.add({
      ...tpl.data,
      name: `${tpl.data.name} - Copy`
    });
    this.rootStore.saveSettings({
      [`${tpl.data.type}Template`]: newTpl.id
    });
    this.rootStore.routerStore.goTo("/templates/edit", { id: newTpl.id });
  }

  prepareTemplate(templateId, data) {
    const tpl = this.rootStore.templates.templateData(templateId).get();
    const client = this.rootStore.clients.clientData(data.data.client).get();
    let content = tpl.data.content as string;
    if (tpl.data.type === "invoice") {
      Object.keys(InvoiceFieldMap).forEach(field => {
        content = content.replace(
          new RegExp(`{%${field}%}`, "g"),
          InvoiceFieldMap[field](data, this.rootStore.user, client)
        );
      });
      const itemBlock = content.substring(
        content.indexOf(ITEM_BLOCK_BEGIN) + ITEM_BLOCK_BEGIN.length,
        content.indexOf(ITEM_BLOCK_END)
      );

      let itemsText = "";
      data.data.items.forEach(item => {
        let newBlock = itemBlock.substr(0);
        Object.keys(ItemFieldMap).forEach(field => {
          newBlock = newBlock.replace(
            new RegExp(`{%${field}%}`, "g"),
            ItemFieldMap[field](item)
          );
        });
        itemsText += newBlock;
      });
      content =
        content.substring(0, content.indexOf(ITEM_BLOCK_BEGIN)) +
        itemsText +
        content.substr(content.indexOf(ITEM_BLOCK_END) + ITEM_BLOCK_END.length);
    } else {
      Object.keys(ReceiptFieldMap).forEach(field => {
        content = content.replace(
          new RegExp(`{%${field}%}`, "g"),
          ReceiptFieldMap[field](data, this.rootStore.user, client)
        );
      });
    }
    return content;
  }

  public print = async (templateId, data) => {
    const winPrint = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0"
    );
    winPrint.document.write(this.prepareTemplate(templateId, data));
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    winPrint.close();
  };

  public savePDF = async (templateId, data, title) => {
    function saveFile(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const a = document.createElement("a");
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      }
    }
    const pdfRocket = (html, savePdf) => {
      const req = new XMLHttpRequest();

      const url = "//api.html2pdfrocket.com/pdf";
      const apiKey = "961f3644-8d20-49d1-9001-c58df38ad18f";
      // Additional parameters can be added here

      req.onload = event => {
        savePdf(req.response);
      };

      req.open("POST", url, true);
      req.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      req.responseType = "blob";

      req.send("apikey=" + apiKey + "&value=" + encodeURIComponent(html));
    };
    pdfRocket(this.prepareTemplate(templateId, data), result => {
      saveFile(result, `${title}.pdf`);
    });
  };
}
