import * as React from "react";
import { inject, observer } from "mobx-react";
import { EmptyState, Card, Page, Layout, Button } from "@shopify/polaris";
import { RootStoreProps } from "../store";
import Chart from "react-google-charts";
import dayjs from "dayjs";
import Loading from "../components/Loading";
import { Loading as LoadingBar } from "@shopify/polaris";

const Dashboard = inject("rootStore")(
  observer(({ rootStore }: RootStoreProps) => {
    const dataByDate = rootStore.receipts.collection.docs.map(doc => [
      dayjs(doc.data.issueDate).toDate(),
      doc.data.amount
    ]);
    const byClient = {};
    rootStore.receipts.collection.docs.forEach(doc => {
      const client = rootStore.clients.clientData(doc.data.client).get();
      if (!byClient[client.data.fullName]) byClient[client.data.fullName] = 0;
      byClient[client.data.fullName] += doc.data.amount;
    });
    const dataByClient = Object.keys(byClient).map(client => [
      client,
      byClient[client]
    ]);
    return (
      <Page title="Dashboard">
        <Layout>
          <Layout.Section secondary>
            <Card title="Earnings by date">
              <Chart
                height={300}
                chartType="LineChart"
                loader={<Loading />}
                chartPackages={["corechart", "controls"]}
                controls={[
                  {
                    controlType: "DateRangeFilter",
                    controlPosition: "bottom",
                    options: {
                      filterColumnLabel: "Date",
                      ui: { format: { pattern: "yyyy" } }
                    }
                  }
                ]}
                data={[
                  [{ type: "date", label: "Date" }, "Income"],
                  ...dataByDate
                ]}
                options={{
                  legend: { position: "none" },
                  hAxis: {
                    title: null,

                    format: "MM/dd/YY",
                    gridlines: { count: 15 }
                  },
                  chart: {
                    title: "Earnings",
                    subtitle: "this year"
                  }
                }}
              />
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card title="Earnings by client">
              <Chart
                height={300}
                chartType="PieChart"
                loader={<Loading />}
                data={[["Client", "Income"], ...dataByClient]}
                options={{
                  legend: { position: "none" }
                }}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    );
  })
);

const Home = inject("rootStore")(
  observer(({ rootStore }: RootStoreProps) => {
    if (
      rootStore.initializing ||
      (rootStore.userId && rootStore.user.isLoading)
    )
      return <LoadingBar />;
    if (rootStore.user.hasData) return <Dashboard />;
    return (
      <EmptyState
        image={require("../assets/undraw_printing_invoices_5r4r.svg")}
        heading="Welcome to Invoicy. Please Login."
        action={{
          content: "Login",
          onAction: rootStore.login
        }}
      />
    );
  })
);

export default Home;
