import { RootStore } from ".";
import { Document, Collection } from "../firestorter";
import { computed, observable } from "mobx";
import {
  DocumentSource,
  IDocumentOptions,
  CollectionSource,
  ICollectionOptions,
  Mode
} from "../firestorter/Types";
import { struct } from "superstruct";
import { firestore } from "firebase/firestore";

export class Receipt extends Document {
  constructor(source: DocumentSource, options: IDocumentOptions) {
    super(source, {
      ...(options || {}),
      schema: struct({
        amount: "number",
        receiptNumber: "number",
        client: "string",
        invoice: "string?",
        issueDate: "string",
        paymentDate: "string?",
        paymentMethod: "string?"
      })
    });
  }
}

export class ReceiptStore {
  constructor(private rootStore: RootStore) {}
  @observable error: string;
  @observable saving: boolean;
  @observable removing: boolean;

  receiptDocument = new Receipt(undefined, { mode: Mode.Off });
  collection = new Collection(() => `users/${this.rootStore.userId}/receipts`, {
    query: (ref: firestore.Query) => ref.orderBy("receiptNumber", "desc"),
    createDocument: (
      source: CollectionSource,
      options: ICollectionOptions<Receipt>
    ) => new Receipt(source, options)
  });

  changeSorting(field: string, order: "desc" | "asc") {
    this.collection.query = (ref: firestore.Query) => ref.orderBy(field, order);
  }

  public receiptData(id: string) {
    return computed(() => this.collection.docs.find(item => item.id === id));
  }

  public async loadReceiptDocument(id: string) {
    this.receiptDocument.path = `users/${this.rootStore.userId}/receipts/${id}`;
    await this.receiptDocument.fetch();
    return this.receiptDocument;
  }

  public print = async (id: string) => {
    await this.rootStore.templates.print(
      this.rootStore.user.data.receiptTemplate,
      this.receiptData(id).get()
    );
  };

  public getReceiptTitle(receipt) {
    return computed(() => {
      const clientDoc = this.rootStore.clients
        .clientData(receipt.data.client)
        .get();
      return `Receipt #${receipt.data.receiptNumber} for ${
        clientDoc ? clientDoc.data.fullName : ""
      }`;
    });
  }

  public savePDF = async (id: string) => {
    const receipt = this.receiptData(id).get();
    await this.rootStore.templates.savePDF(
      this.rootStore.user.data.receiptTemplate,
      receipt,
      this.getReceiptTitle(receipt).get()
    );
  };

  public save = async (data: any) => {
    this.saving = true;
    this.error = undefined;
    try {
      if (this.receiptDocument.hasData) {
        await this.receiptDocument.update(data);
      } else {
        const id = await this.rootStore.incCounter("receiptCounter");
        await this.collection.add({
          ...data,
          receiptNumber: id
        });
      }
      this.rootStore.user.update({ lastClient: data.client });
      this.rootStore.routerStore.goTo("receipts");
    } catch (ex) {
      console.log(ex);
      this.error = ex.message;
    }
    this.saving = false;
  };

  public delete = async () => {
    this.error = undefined;
    this.removing = true;
    try {
      await this.receiptDocument.delete();
      this.rootStore.routerStore.goTo("receipts");
    } catch (ex) {
      console.log(ex);
      this.error = ex.message;
    }
    this.removing = false;
  };
}
