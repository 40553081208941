export const currencies = {
  USD: "$",
  CAD: "CA$",
  EUR: "€",
  AED: "AED",
  AFN: "Af",
  ALL: "ALL",
  AMD: "AMD",
  ARS: "AR$",
  AUD: "AU$",
  AZN: "man.",
  BAM: "KM",
  BDT: "Tk",
  BGN: "BGN",
  BHD: "BD",
  BIF: "FBu",
  BND: "BN$",
  BOB: "Bs",
  BRL: "R$",
  BWP: "BWP",
  BYR: "BYR",
  BZD: "BZ$",
  CDF: "CDF",
  CHF: "CHF",
  CLP: "CL$",
  CNY: "CN¥",
  COP: "CO$",
  CRC: "₡",
  CVE: "CV$",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "Dkr",
  DOP: "RD$",
  DZD: "DA",
  EEK: "Ekr",
  EGP: "EGP",
  ERN: "Nfk",
  ETB: "Br",
  GBP: "£",
  GEL: "GEL",
  GHS: "GH₵",
  GNF: "FG",
  GTQ: "GTQ",
  HKD: "HK$",
  HNL: "HNL",
  HRK: "kn",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  INR: "Rs",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "Ikr",
  JMD: "J$",
  JOD: "JD",
  JPY: "¥",
  KES: "Ksh",
  KHR: "KHR",
  KMF: "CF",
  KRW: "₩",
  KWD: "KD",
  KZT: "KZT",
  LBP: "LB£",
  LKR: "SLRs",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MOP: "MOP$",
  MUR: "MURs",
  MXN: "MX$",
  MYR: "RM",
  MZN: "MTn",
  NAD: "N$",
  NGN: "₦",
  NIO: "C$",
  NOK: "Nkr",
  NPR: "NPRs",
  NZD: "NZ$",
  OMR: "OMR",
  PAB: "B/.",
  PEN: "S/.",
  PHP: "₱",
  PKR: "PKRs",
  PLN: "zł",
  PYG: "₲",
  QAR: "QR",
  RON: "RON",
  RSD: "din.",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SR",
  SDG: "SDG",
  SEK: "Skr",
  SGD: "S$",
  SOS: "Ssh",
  SYP: "SY£",
  THB: "฿",
  TND: "DT",
  TOP: "T$",
  TRY: "TL",
  TTD: "TT$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  UYU: "$U",
  UZS: "UZS",
  VEF: "Bs.F.",
  VND: "₫",
  XAF: "FCFA",
  XOF: "CFA",
  YER: "YR",
  ZAR: "R",
  ZMK: "ZK"
};

export const countries = {
  Canada: "CAD",
  "Iran (islamic republic of)": "IRR",
  Cambodia: "KHR",
  Ethiopia: "ETB",
  Cameroon: "XAF",
  Bahrain: "BHD",
  "Virgin islands (u.s.)": "USD",
  "Tanzania, united republic of": "TZS",
  "Western sahara": "MAD",
  "Faroe islands": "DKK",
  Guatemala: "GTQ",
  "European union": "EUR",
  "Isle of man": "GBP",
  Pakistan: "PKR",
  Oman: "OMR",
  "Viet nam": "VND",
  "American samoa": "USD",
  Netherlands: "EUR",
  Gabon: "XAF",
  "Cook islands": "NZD",
  Yemen: "YER",
  Jersey: "GBP",
  Jamaica: "JMD",
  Greenland: "DKK",
  "Serbia and montenegro": "EUR",
  Guam: "USD",
  India: "INR",
  "Svalbard and jan mayen": "NOK",
  "Moldova (the republic of)": "MDL",
  Kenya: "KES",
  Macao: "MOP",
  Turkey: "TRY",
  "Saudi arabia": "SAR",
  "Norfolk island": "AUD",
  Bangladesh: "BDT",
  "Cocos (keeling) islands": "AUD",
  "Holy see": "EUR",
  Israel: "ILS",
  Rwanda: "RWF",
  Somalia: "SOS",
  "Puerto rico": "USD",
  Nauru: "AUD",
  Norway: "NOK",
  "Czech republic": "CZK",
  Benin: "XOF",
  Singapore: "SGD",
  Montenegro: "EUR",
  "Turks and caicos islands": "USD",
  Togo: "XOF",
  "British indian ocean territory": "USD",
  "Burkina faso": "XOF",
  Philippines: "PHP",
  Tonga: "TOP",
  Liechtenstein: "CHF",
  Libya: "LYD",
  Indonesia: "IDR",
  Mauritius: "MUR",
  "United arab emirates": "AED",
  Sweden: "SEK",
  "Venezuela (bolivarian republic of)": "VEF",
  Chad: "XAF",
  Tokelau: "NZD",
  "Syrian arab republic": "SYP",
  Malaysia: "MYR",
  Senegal: "XOF",
  "French guiana": "EUR",
  "Northern mariana islands": "USD",
  Hungary: "HUF",
  "Saint martin (french part)": "EUR",
  Pitcairn: "NZD",
  Kuwait: "KWD",
  Panama: "USD",
  Nigeria: "NGN",
  Australia: "AUD",
  Algeria: "DZD",
  Tuvalu: "AUD",
  "Trinidad and tobago": "TTD",
  "United kingdom": "GBP",
  Kiribati: "AUD",
  Haiti: "USD",
  Belize: "BZD",
  "Bouvet island": "NOK",
  Denmark: "DKK",
  Morocco: "MAD",
  Namibia: "ZAR",
  Guernsey: "GBP",
  Thailand: "THB",
  "Marshall islands": "USD",
  "Dominican republic": "DOP",
  Congo: "XAF",
  Iraq: "IQD",
  Estonia: "EEK",
  Lebanon: "LBP",
  "Central african republic": "XAF",
  "Cabo verde": "CVE",
  Djibouti: "DJF",
  Burundi: "BIF",
  "New zealand": "NZD",
  "Russian federation": "RUB",
  Qatar: "QAR",
  "Virgin islands (british)": "USD",
  Palau: "USD",
  Nepal: "NPR",
  "El salvador": "USD",
  "C\u00c3\u201dte d'ivoire": "XOF",
  Niger: "XOF",
  "Bonaire, sint eustatius and saba": "USD",
  Venezuela: "VEF",
  Zambia: "ZMK",
  "Costa rica": "CRC",
  "Heard island and mcdonald islands": "AUD",
  Jordan: "JOD",
  Comoros: "KMF",
  "Taiwan (province of china)": "TWD",
  Eritrea: "ERN",
  "United states": "USD",
  "Macedonia (the former yugoslav republic of)": "MKD",
  "Brunei darussalam": "BND",
  "Korea (the republic of)": "KRW",
  Egypt: "EGP",
  Japan: "JPY",
  "Sri lanka": "LKR",
  "Hong kong": "HKD",
  "Micronesia (federated states of)": "USD",
  Honduras: "HNL",
  Myanmar: "MMK",
  Tunisia: "TND",
  "French southern territories": "EUR",
  "Saint barth\u00c3\u2030lemy": "EUR",
  Serbia: "RSD",
  Sudan: "SDG",
  "Congo (the democratic republic of the)": "CDF",
  "Christmas island": "AUD",
  "South sudan": "SDG",
  Paraguay: "PYG",
  Croatia: "HRK",
  Botswana: "BWP",
  Niue: "NZD"
};
