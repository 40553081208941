import { RouterState, Route, routerStateToUrl } from "mobx-state-router";

function sleep(ms): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function checkForUser(fromState, toState, routerStore) {
  const { rootStore } = routerStore;
  while (rootStore.initializing) {
    await sleep(10);
  }
  if (!rootStore.user.hasData) {
    return Promise.reject(new RouterState("home"));
  }
  return Promise.resolve();
}

export const routes: Route[] = [
  {
    name: "invoices",
    beforeEnter: checkForUser,
    pattern: "/invoices",
    onEnter: async (fromState, toState, routerStore) => {
      return Promise.resolve();
    }
  },
  {
    name: "invoice",
    beforeEnter: checkForUser,
    pattern: "/invoices/:id",
    onEnter: async (fromState, toState, routerStore) => {
      const { rootStore } = routerStore;
      const { id } = toState.params;
      rootStore.invoices
        .loadInvoiceDocument(id)
        .then(data => rootStore.clients.loadClientDocument(data.data.client));
      return Promise.resolve();
    }
  },
  {
    name: "settings",
    beforeEnter: checkForUser,
    pattern: "/settings",
    onEnter: async (fromState, toState, routerStore) => {
      return Promise.resolve();
    }
  },
  {
    name: "clients",
    beforeEnter: checkForUser,
    pattern: "/clients",
    onEnter: async (fromState, toState, routerStore) => {
      return Promise.resolve();
    }
  },
  {
    name: "client",
    beforeEnter: checkForUser,
    pattern: "/clients/:id",
    onEnter: async (fromState, toState, routerStore) => {
      const { rootStore } = routerStore;
      const { id } = toState.params;
      rootStore.clients.loadClientDocument(id);
      return Promise.resolve();
    }
  },
  {
    name: "template",
    pattern: "/templates/:id",
    beforeEnter: checkForUser,
    onEnter: async (fromState, toState, routerStore) => {
      const { rootStore } = routerStore;
      const { id } = toState.params;
      rootStore.templates.loadTemplateDocument(id);
      return Promise.resolve();
    }
  },
  {
    name: "templates",
    beforeEnter: checkForUser,
    pattern: "/templates",
    onEnter: async (fromState, toState, routerStore) => {
      return Promise.resolve();
    }
  },
  {
    name: "receipts",
    beforeEnter: checkForUser,
    pattern: "/receipts",
    onEnter: async (fromState, toState, routerStore) => {
      return Promise.resolve();
    }
  },
  {
    name: "receipt",
    beforeEnter: checkForUser,
    pattern: "/receipts/:id",
    onEnter: async (fromState, toState, routerStore) => {
      const { rootStore } = routerStore;
      const { id } = toState.params;
      rootStore.receipts.loadReceiptDocument(id);
      return Promise.resolve();
    }
  },
  {
    name: "home",
    pattern: "/",
    beforeEnter: async (fromState, toState, routerStore) => {
      return Promise.resolve();
    }
  },
  { name: "notFound", pattern: "/not-found" }
];
