import { RootStore } from ".";
import { Document, Collection } from "../firestorter";
import { computed, observable, autorun } from "mobx";
import {
  DocumentSource,
  IDocumentOptions,
  Mode,
  CollectionSource,
  ICollectionOptions
} from "../firestorter/Types";
import { struct } from "superstruct";
import { currencies, countries } from "../pages/clients/countries";
import * as firebase from "firebase/app";

export class Client extends Document {
  constructor(source: DocumentSource, options: IDocumentOptions) {
    super(source, {
      ...(options || {}),
      schema: struct({
        businessNumber: "string?",
        email: "string",
        country: "string?",
        phone: "string?",
        file: "string?",
        logo: "string?",
        address: "string",
        city: "string",
        zip: "string",
        fullName: "string"
      })
    });
  }

  async loadImageUrl() {
    if (!this.data.file || !this.hasData) return undefined;
    try {
      return await firebase
        .storage()
        .ref(`thumb@64_${this.data.file}`)
        .getDownloadURL();
    } catch (ex) {
      return undefined;
    }
  }

  get currency() {
    return this.hasData && this.data.country && countries[this.data.country]
      ? currencies[countries[this.data.country]]
      : currencies["USD"];
  }
}

export class ClientStore {
  constructor(private rootStore: RootStore) {
    autorun(async () => {
      for (const element of this.collection.docs) {
        if (!this.images.has(element.id)) {
          this.images.set(element.id, await element.loadImageUrl());
        }
      }
    });
  }

  images = observable.map();

  collection = new Collection<Client>(
    () => `users/${this.rootStore.userId}/clients`,
    {
      query: ref => ref.orderBy("fullName", "desc"),
      createDocument: (
        source: CollectionSource,
        options: ICollectionOptions<Client>
      ) => new Client(source, options)
    }
  );
  changeSorting(field: string, order: "desc" | "asc") {
    this.collection.query = ref => ref.orderBy(field, order);
  }
  public clientData(id) {
    return computed(() => {
      const ret = this.collection.docs.find(item => item.id === id) as Client;
      return ret;
    });
  }

  clientDocument = new Client(undefined, { mode: Mode.Off });

  public async loadClientDocument(id) {
    this.clientDocument.path = `users/${this.rootStore.userId}/clients/${id}`;
    await this.clientDocument.fetch();
    if (!this.images.has(this.clientDocument.id)) {
      this.images.set(
        this.clientDocument.id,
        await this.clientDocument.loadImageUrl()
      );
    }
  }

  @computed get clientCurrency() {
    return this.clientDocument.currency;
  }

  @observable error;
  @observable saving;
  @observable removing;

  async upload(file, clientId) {
    if (!file) return undefined;
    if (file instanceof File) {
      // freshly dropped
      const fileParts = file.name.split(".");
      const result = await firebase
        .storage()
        .ref(`${clientId}.${fileParts[fileParts.length - 1]}`)
        .put(file);
      return result.ref.fullPath;
    } else {
      return file;
    }
  }
  save = async data => {
    const file = data.file;
    delete data.file;
    this.saving = true;
    try {
      if (this.clientDocument.hasData) {
        const res = { ...data };
        const fileUrl = await this.upload(file, this.clientDocument.id);
        if (fileUrl) res.file = fileUrl;
        await this.clientDocument.update(res);
      } else {
        const client = await this.collection.add(data);
        const fileUrl = await this.upload(file, client.id);
        if (fileUrl) {
          await client.update({ file: fileUrl });
        }
      }
      this.rootStore.routerStore.goTo("clients");
    } catch (ex) {
      this.error = ex.message;
    }
    this.saving = false;
  };

  remove = async () => {
    this.removing = true;
    try {
      await this.clientDocument.delete();
      this.rootStore.routerStore.goTo("clients");
    } catch (ex) {
      this.error = ex.message;
    }
    this.removing = false;
  };
}
