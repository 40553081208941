import * as React from "react";
import { viewMap } from "./pages/routes";
import {
  AppProvider,
  Frame,
  TopBar,
  Navigation,
  IconableAction,
  Loading,
  Toast
} from "@shopify/polaris";
import { inject, observer } from "mobx-react";
import { RootStore } from "./store";
import { observable } from "mobx";
import { gravatar } from "./components/gravatar";
const logo = require("./assets/logo.svg");

interface UserActionSection {
  id: string;
  items: IconableAction[];
}

@inject("rootStore")
@observer
class App extends React.Component<{ rootStore?: RootStore }> {
  @observable userMenuOpen = false;
  @observable mobileNavOpen = false;
  render() {
    const store = this.props.rootStore;
    const userMenuActions: UserActionSection[] = [
      {
        id: "1",
        items: [
          {
            content: "Logout",
            icon: "logOut",
            onAction: store.logout
          }
        ]
      }
    ];

    const userName = store.user.hasData ? store.user.data.fullName : "";
    const userEmail = store.user.hasData ? store.user.data.email : "";
    const initials = userName
      .split("")
      .filter(c => c === c.toUpperCase())
      .join("");
    const userAvatar = store.user.hasData
      ? gravatar(this.props.rootStore.user.data.email)
      : null;
    const navigationUserMenuMarkup = (
      <Navigation.UserMenu
        actions={userMenuActions}
        name={userName}
        avatarSource={userAvatar}
        avatarInitials={initials}
      />
    );
    const userMenuMarkup = (
      <TopBar.UserMenu
        actions={userMenuActions}
        name={userName}
        detail={userEmail}
        initials={initials}
        avatar={userAvatar}
        open={this.userMenuOpen}
        onToggle={() => (this.userMenuOpen = !this.userMenuOpen)}
      />
    );

    const topBarMarkup = (
      <TopBar
        showNavigationToggle={true}
        userMenu={userMenuMarkup}
        onNavigationToggle={() => (this.mobileNavOpen = !this.mobileNavOpen)}
      />
    );

    const navigationMarkup = (
      <Navigation location="/" userMenu={navigationUserMenuMarkup}>
        <Navigation.Section
          items={[
            {
              label: "Dashboard",
              icon: "home",
              onClick: () => {
                store.routerStore.goTo("home");
              }
            },
            {
              label: "Clients",
              icon: "profile",
              onClick: () => {
                store.routerStore.goTo("clients");
              }
            },
            {
              label: "Invoices",
              icon: "orders",
              onClick: () => {
                store.routerStore.goTo("invoices");
              }
            },
            {
              label: "Receipts",
              icon: "products",
              onClick: () => {
                store.routerStore.goTo("receipts");
              }
            }
          ]}
        />
        <Navigation.Section
          items={[
            {
              label: "Settings",
              icon: "view",
              onClick: () => {
                store.routerStore.goTo("settings");
              }
            },
            {
              label: "Templates",
              icon: "notes",
              onClick: () => {
                store.routerStore.goTo("templates");
              }
            }
          ]}
          separator
        />
        <Navigation.Section
          items={[
            {
              label: "Help center",
              icon: "help",
              onClick: () => {}
            }
          ]}
          separator
        />
      </Navigation>
    );

    const theme = {
      colors: {
        topBar: {
          background: "#357997",
          backgroundDarker: "#357997",
          backgroundLighter: "#6192a9",
          color: "#FFFFFF"
        }
      },
      logo: {
        width: 124,
        topBarSource: logo,
        contextualSaveBarSource: logo,
        url: "https://invoicy.reflog.me",
        accessibilityLabel: "Invocy"
      }
    };
    const View = viewMap[store.routerStore.routerState.routeName];
    return (
      <AppProvider theme={theme}>
        <Frame
          showMobileNavigation={this.mobileNavOpen}
          topBar={store.userId && topBarMarkup}
          onNavigationDismiss={() => (this.mobileNavOpen = false)}
          navigation={store.userId && navigationMarkup}
        >
          {store.initializing ? (
            <Loading />
          ) : (
            <React.Suspense fallback={<Loading />}>{View}</React.Suspense>
          )}
          {store.toastMessage && (
            <Toast
              content={store.toastMessage}
              onDismiss={() => (store.toastMessage = null)}
            />
          )}
        </Frame>
      </AppProvider>
    );
  }
}

export default App;
