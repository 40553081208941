import * as React from "react";
import * as ReactDOM from "react-dom";

import App from "./App";
import { Provider } from "mobx-react";
import { RootStore } from "./store";

const rootStore = new RootStore();
ReactDOM.render(
  <Provider rootStore={rootStore}>
    <App />
  </Provider>,
  document.getElementById("root")
);
