import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import { Document, initFirestorter, Collection, Mode } from "../firestorter";
import { observable, action } from "mobx";
import { InvoiceStore } from "./invoices";
import { ClientStore } from "./clients";
import { ReceiptStore } from "./receipts";
import { createBrowserHistory } from "history";
import { RouterState, RouterStore, HistoryAdapter } from "mobx-state-router";
import { routes } from "./routes";
import { TemplateStore } from "./templates";
import { isNumber } from "util";
const notFound = new RouterState("notFound");

const clientCredentials = {
  apiKey: "AIzaSyD7TBPyalm6zkfGhHZ2ic30oZB5VzWyGzU",
  authDomain: "invoicy-f8937.firebaseapp.com",
  databaseURL: "https://invoicy-f8937.firebaseio.com",
  projectId: "invoicy-f8937",
  storageBucket: "invoicy-f8937.appspot.com",
  messagingSenderId: "480337069852"
};

let auth: firebase.auth.Auth;
export let firestore: firebase.firestore.Firestore;

try {
  firebase.initializeApp(clientCredentials);
  initFirestorter({ firebase });
  auth = firebase.auth();
  firestore = firebase.firestore();
  firestore.settings({ timestampsInSnapshots: true });
} catch (err) {
  // taken from https://github.com/now-examples/next-news/blob/master/lib/db.js
  if (!/already exists/.test(err.message)) {
    console.error("Firebase initialization error", err.stack);
  } else {
    throw err;
  }
}

export class RootStore {
  provider = new firebase.auth.GoogleAuthProvider();
  invoices = new InvoiceStore(this);
  clients = new ClientStore(this);
  receipts = new ReceiptStore(this);
  templates = new TemplateStore(this);
  history = createBrowserHistory();
  historyAdapter: HistoryAdapter;
  routerStore = new RouterStore(this, routes, notFound);

  login = async () => {
    const result = await auth.signInWithPopup(this.provider);
    if (result.user) {
      await this.routerStore.goTo("invoices");
    }
  };

  @observable initializing = true;
  @observable userId = undefined;
  @observable userIsSaving = false;
  user = new Document(() => `users/${this.userId}`, { mode: Mode.On });
  @observable toastMessage = undefined;
  @action
  saveSettings = async settings => {
    this.userIsSaving = true;
    try {
      await this.user.update(settings);
    } catch (ex) {
      this.toastMessage = "Cannot save user settings!";
    }
    this.userIsSaving = false;
  };

  public incCounter(counter): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.user.ref.firestore.runTransaction(transaction => {
        return transaction
          .get(this.user.ref)
          .then(sfDoc => {
            if (sfDoc.exists) {
              const value = sfDoc.data()[counter];
              let newCounter = 1;
              if (typeof value === "number") {
                newCounter = value + 1;
              }
              transaction.update(this.user.ref, { [counter]: newCounter });
              resolve(newCounter);
            }
          })
          .catch(reject);
      });
    });
  }

  logout = () => {
    this.initializing = true;
    this.userId = undefined;
    auth.signOut();
    this.routerStore.goTo("home");
  };

  constructor() {
    this.historyAdapter = new HistoryAdapter(this.routerStore, this.history);
    this.historyAdapter.observeRouterStateChanges();

    auth.onAuthStateChanged(async user => {
      this.userId = user ? user.uid : undefined;
      this.initializing = false;
    });
  }
}

export function numberWithCommas(x) {
  if (!isNumber(x)) return "";
  const parts = x.toFixed(2).split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export type RootStoreProps = {
  rootStore?: RootStore;
};
