import Loading from "../components/Loading";
import * as React from "react";
import { ViewMap } from "mobx-state-router";
import { inject, observer } from "mobx-react";
import Home from "./home";

const Settings = React.lazy(() => import("./settings"));

const Clients = React.lazy(() => import("./clients"));

const Invoices = React.lazy(() => import("./invoices"));

const Receipts = React.lazy(() => import("./receipts"));

const Templates = React.lazy(() => import("./templates"));

export const viewMap: ViewMap = {
  __initial__: <Loading />,
  notFound: <div>not found!</div>,
  home: <Home />,
  receipt: <Receipts />,
  receipts: <Receipts />,
  invoice: <Invoices />,
  invoices: <Invoices />,
  templates: <Templates />,
  template: <Templates />,
  client: <Clients />,
  clients: <Clients />,
  settings: <Settings />
};
